import { DOWN, LEFT, RIGHT, UP } from "../core/constants";
import { border, color, flexbox, layout, position, space } from "styled-system";

import Arrow from "../assets/arrow.svg";
import Box from "./Box";
import styled from "styled-components";

const ArrowButtonBase = styled.button`
  ${color}
  ${space}
  ${border}
  ${flexbox}
  ${layout}
  ${position}
  body:not(.is-touch) & {
    &:hover {
      #ring {
        opacity: 1 !important;
      }
    }
  }
`;

function ArrowButton({ label, handleClick, direction, ...props }) {
  let transform;
  if (direction === DOWN) {
    transform = "rotate(90deg)";
  }
  if (direction === UP) {
    transform = "rotate(270deg)";
  }
  if (direction === LEFT) {
    transform = "rotate(180deg)";
  }
  if (direction === RIGHT) {
    transform = "rotate(0deg)";
  }
  return (
    <ArrowButtonBase
      display="flex"
      alignItems="center"
      position="relative"
      onClick={handleClick}
      {...props}
    >
      {label && label}
      <Box
        borderRadius="100%"
        height={48}
        width={48}
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
      >
        <Box
          transform={transform}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Arrow />
        </Box>
        <Box
          id="ring"
          transition="opacity 300ms"
          border="2px solid #F5F5F4"
          borderRadius="100%"
          position="absolute"
          height="100%"
          width="100%"
          top={0}
          left={0}
          opacity={0.5}
        />
      </Box>
    </ArrowButtonBase>
  );
}

export default ArrowButton;
