import CapSize from "./CapSize";

function MediumHeading({ children, textAlign }) {
  return (
    <CapSize
      fontFamily="bold"
      fontSize={[30, 30, 50, 50]}
      lineHeight={["40px", "40px", "60px", "60px"]}
      textAlign={textAlign}
    >
      {children}
    </CapSize>
  );
}

export default MediumHeading;
