import ArrowNavy from "../assets/arrow-navy.svg";
import PlayIcon from "../assets/play-icon.svg";
import Box from "./Box";
import CapSize from "./CapSize";
import Button from "./Button";
import Link from "next/link";
import styled from "styled-components";

const GreenButtonInner = styled.a`
  display: inline-flex;
  position: relative;
  height: 50px;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #c7ff00;
    transition: background-color 300ms;
    border-radius: 9999px;
    z-index: 1;
    transform: scale3d(1, 1, 1);
  }
  body:not(.is-touch) & {
    :hover {
      &::after {
        background-color: white;
      }
    }
  }
`;

function GreenButton({ href, external, handleClick, children }) {
  const arrowButtonInner = (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex={2}
      px={30}
    >
      <CapSize
        fontSize="18px"
        color="trailstoneNavy"
        pr="10px"
        fontFamily="medium"
      >
        {children}
      </CapSize>
      <Box>
        <ArrowNavy />
      </Box>
    </Box>
  );

  const playButtonInner = (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex={2}
      px={30}
    >
      <Box>
        <PlayIcon />
      </Box>
      <CapSize
        fontSize="18px"
        color="trailstoneNavy"
        pl="10px"
        fontFamily="medium"
      >
        {children}
      </CapSize>
    </Box>
  );

  if (!href) {
    return (
      <Button onClick={handleClick}>
        <GreenButtonInner as="div" height={50}>
          {playButtonInner}
        </GreenButtonInner>
      </Button>
    );
  } else if (external) {
    return (
      <a href={href}>
        <GreenButtonInner as="div" height={50}>
          {arrowButtonInner}
        </GreenButtonInner>
      </a>
    );
  } else {
    return (
      <Link href={href} passHref>
        <GreenButtonInner height={50}>{arrowButtonInner}</GreenButtonInner>
      </Link>
    );
  }
}

export default GreenButton;
