import { border, color, flexbox, layout, position, space } from "styled-system";

import styled from "styled-components";

const Button = styled.button`
  ${color}
  ${space}
  ${border}
  ${flexbox}
  ${layout}
  ${position}
`;

Button.defaultProps = {};

export default Button;
