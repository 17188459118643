import CapSize from "./CapSize";

function BodyText({ children, ...props }) {
  return (
    <CapSize fontSize={["15px", "15px", 18, 18]} lineHeight="28px" {...props}>
      {children}
    </CapSize>
  );
}

export default BodyText;
